import Layout from 'common/layouts/Layout';
import { Link } from 'component-library/components/links/Link';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { Heading } from 'component-library/components/typography/Heading';
import { Subheading } from 'component-library/components/typography/Subheading';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { LegalWrapper } from 'components/pages/legal/shared';
import { SEO } from 'components/SEO';
import React, { FC } from 'react';

interface LegalProps {}

const Legal: FC<LegalProps> = () => (
  <Layout>
    <Navigation />
    <PageWrapper>
      <LegalWrapper>
        <Heading marginBottom={20} variant={4}>
          Legal
        </Heading>
        <Link inline variant={4} to="/legal/privacy-policy">
          Privacy Policy
        </Link>
        <br />
        <Link inline variant={4} to="/legal/terms-and-conditions">
          Terms & Conditions
        </Link>
        <br />
        <Link inline variant={4} to="/legal/electronic-disclosure-and-consent">
          Electronic Disclosure & Consent
        </Link>
        <br />
        <Link inline variant={4} to="/legal/referral">
          Referral Program
        </Link>
        <br />
        <Link
          inline
          variant={4}
          to="/legal/consumer-debit-cardholder-agreement"
        >
          Consumer Debit Cardholder Agreement
        </Link>
        <br />
        <Link inline variant={4} to="/legal/consumer-deposit-account-agreement">
          Consumer Deposit Account Agreement
        </Link>
        <br />
        <Link
          inline
          variant={4}
          to="/legal/consumer-deposit-account-agreement-disclosures"
        >
          Consumer Deposit Account Disclosures
        </Link>
      </LegalWrapper>
    </PageWrapper>
  </Layout>
);

export default Legal;

export const Head = () => (
  <SEO
    title="Legal — Retirable"
    description="Retirable is here to answer your frequently asked questions about legal and compliance."
  />
);
