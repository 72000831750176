import { useLocation } from '@reach/router';
import { Link as GatsbyLink } from 'gatsby';
import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { bodyStyles, BodyStylesProps } from '../typography/Body';
import { isExternalLink } from './linkUtils';

interface LinkStylesProps extends BodyStylesProps {
  inline?: boolean;
  underline?: boolean;
}

const linkStyles = css<LinkStylesProps>`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  text-decoration: ${({ inline, underline }) =>
    inline || underline ? 'underline' : 'none'};

  ${bodyStyles}
`;

const InternalLink = styled(GatsbyLink)`
  ${linkStyles};
`;

const ExternalLink = styled.a`
  ${linkStyles};
`;

interface LinkProps extends LinkStylesProps {
  to: string;
}

export const Link: FC<PropsWithChildren<LinkProps>> = ({
  to,
  children,
  ...rest
}) => {
  const location = useLocation();
  const destination = `${to}${location.search}`;

  if (isExternalLink(to)) {
    return (
      <ExternalLink href={destination} target="_blank" {...rest}>
        {children}
      </ExternalLink>
    );
  }

  return (
    <InternalLink to={destination} {...rest}>
      {children}
    </InternalLink>
  );
};
